var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.isMobile ? [_c('form', {
    ref: "fm",
    attrs: {
      "name": "fm",
      "method": "POST",
      "accept-charset": "euc-kr",
      "action": "allat_approval.php"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.shopId,
      expression: "shopId"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "상점 ID",
      "name": "allat_shop_id",
      "value": "",
      "size": "19",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.shopId
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.shopId = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderNo,
      expression: "orderNo"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "주문번호",
      "name": "allat_order_no",
      "value": "",
      "size": "19",
      "maxlength": "70"
    },
    domProps: {
      "value": _vm.orderNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderNo = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentAmount,
      expression: "paymentAmount"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "승인금액",
      "name": "allat_amt",
      "value": "",
      "size": "19",
      "maxlength": "10"
    },
    domProps: {
      "value": _vm.paymentAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentAmount = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "회원ID",
      "name": "allat_pmember_id",
      "value": "_",
      "size": "19",
      "maxlength": "20"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품코드",
      "name": "allat_product_cd",
      "value": "_",
      "size": "19",
      "maxlength": "1000"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderName,
      expression: "orderName"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "상품명",
      "name": "allat_product_nm",
      "value": "",
      "size": "19",
      "maxlength": "1000"
    },
    domProps: {
      "value": _vm.orderName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "결제자성명",
      "name": "allat_buyer_nm",
      "value": "",
      "size": "19",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "수취인성명",
      "name": "allat_recp_nm",
      "value": "",
      "size": "19",
      "maxlength": "20"
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "수취인주소",
      "name": "allat_recp_addr",
      "value": "",
      "size": "19",
      "maxlength": "120"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.responseUrl,
      expression: "responseUrl"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "인증정보수신URL",
      "name": "shop_receive_url",
      "value": "",
      "size": "19"
    },
    domProps: {
      "value": _vm.responseUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.responseUrl = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.test,
      expression: "test"
    }],
    attrs: {
      "type": "hidden",
      "name": "allat_test_yn",
      "size": "19",
      "maxlength": "1"
    },
    domProps: {
      "value": _vm.test
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.test = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.encData,
      expression: "encData",
      modifiers: {
        "lazy": true
      }
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "주문정보암호화필드",
      "name": "allat_enc_data",
      "value": ""
    },
    domProps: {
      "value": _vm.encData
    },
    on: {
      "change": function ($event) {
        _vm.encData = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerEmail,
      expression: "buyerEmail"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "결제 정보 수신 E-mail",
      "name": "allat_email_addr",
      "value": "",
      "size": "19",
      "maxlength": "50"
    },
    domProps: {
      "value": _vm.buyerEmail
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerEmail = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "신용카드 결제 사용 여부",
      "name": "allat_card_yn",
      "value": "Y",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "계좌이체 결제<br>사용 여부",
      "name": "allat_bank_yn",
      "value": "N",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "무통장(가상계좌) 결제<br>사용 여부",
      "name": "allat_vbank_yn",
      "value": "N",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "휴대폰 결제<br>사용 여부",
      "name": "allat_hp_yn",
      "value": "N",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품권 결제<br>사용 여부",
      "name": "allat_ticket_yn",
      "value": "N",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "무통장(가상계좌)<br>인증 Key",
      "name": "allat_account_key",
      "value": "",
      "size": "19",
      "maxlength": "20"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "과세여부",
      "name": "allat_tax_yn",
      "value": "Y",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "할부 사용여부",
      "name": "allat_sell_yn",
      "value": "Y",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "일반/무이자 할부<br>사용여부",
      "name": "allat_zerofee_yn",
      "value": "Y",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "포인트 사용 여부",
      "name": "allat_bonus_yn",
      "value": "N",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "현금 영수증 발급 여부",
      "name": "allat_cash_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품이미지 URL",
      "name": "allat_product_img",
      "value": "http://",
      "size": "19",
      "maxlength": "256"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품 실물 여부: 상품이 실물일 경우 (Y), 상품이 실물이 아닐경우 (N) - Default : N",
      "name": "allat_real_yn",
      "value": "N",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "카드 에스크로<br>적용여부",
      "name": "allat_cardes_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "계좌이체 에스크로<br>적용여부",
      "name": "allat_bankes_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "무통장(가상계좌) 에스<br>크로 적용여부",
      "name": "allat_vbankes_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "휴대폰 에스크로<br>적용여부",
      "name": "allat_hpes_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품권 에스크로<br>적용여부",
      "name": "allat_ticketes_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "주민번호",
      "name": "allat_registry_no",
      "value": "",
      "size": "19",
      "maxlength": "13"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "KB복합결제 적용여부",
      "name": "allat_kbcon_point_yn",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "제공기간",
      "name": "allat_provide_date",
      "value": "",
      "size": "19",
      "maxlength": "25"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "성별",
      "name": "allat_gender",
      "value": "",
      "size": "19",
      "maxlength": "1"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "생년월일",
      "name": "allat_birth_ymd",
      "value": "",
      "size": "19",
      "maxlength": "8"
    }
  })])] : [_c('form', {
    attrs: {
      "name": "sendFm",
      "method": "post",
      "accept-charset": "euc-kr"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.shopId,
      expression: "shopId"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "상점아이디<br>(allat_shop_id)",
      "name": "allat_shop_id",
      "value": ""
    },
    domProps: {
      "value": _vm.shopId
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.shopId = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderNo,
      expression: "orderNo"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "주문번호<br>(allat_order_no)",
      "name": "allat_order_no",
      "value": ""
    },
    domProps: {
      "value": _vm.orderNo
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderNo = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.paymentAmount,
      expression: "paymentAmount"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "승인금액<br>(allat_amt)",
      "name": "allat_amt",
      "value": ""
    },
    domProps: {
      "value": _vm.paymentAmount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.paymentAmount = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "회원ID<br>(allat_pmember_id)",
      "name": "allat_pmember_id",
      "value": "_"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품코드<br>(allat_product_cd)",
      "name": "allat_product_cd",
      "value": "_"
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.orderName,
      expression: "orderName"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "상품명<br>(allat_product_nm)",
      "name": "allat_product_nm",
      "value": ""
    },
    domProps: {
      "value": _vm.orderName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.orderName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "결제자성명<br>(allat_buyer_nm)",
      "name": "allat_buyer_nm",
      "value": ""
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.buyerName,
      expression: "buyerName"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "수취인성명<br>(allat_recp_nm)",
      "name": "allat_recp_nm",
      "value": ""
    },
    domProps: {
      "value": _vm.buyerName
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.buyerName = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "수취인주소<br>(allat_recp_addr)",
      "name": "allat_recp_addr",
      "value": ""
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.responseUrl,
      expression: "responseUrl"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "인증정보수신URL<br>(shop_receive_url)",
      "name": "shop_receive_url"
    },
    domProps: {
      "value": _vm.responseUrl
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.responseUrl = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.lazy",
      value: _vm.encData,
      expression: "encData",
      modifiers: {
        "lazy": true
      }
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "주문정보암호화필드<br>(allat_enc_data)",
      "name": "allat_enc_data"
    },
    domProps: {
      "value": _vm.encData
    },
    on: {
      "change": function ($event) {
        _vm.encData = $event.target.value;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.test,
      expression: "test"
    }],
    attrs: {
      "type": "hidden",
      "placeholder": "테스트여부<br>(allat_test_yn)",
      "name": "allat_test_yn"
    },
    domProps: {
      "value": _vm.test
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.test = $event.target.value;
      }
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "결제창크기자동조절 사용여부<br>(allat_autoscreen_yn)",
      "name": "allat_autoscreen_yn",
      "value": "Y"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "신용카드 결제 사용여부<br>(allat_card_yn)",
      "name": "allat_card_yn",
      "value": "Y"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "계좌이체 결제 사용여부<br>(allat_abank_yn)",
      "name": "allat_abank_yn",
      "value": "N"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "가상계좌 결제 사용여부<br>(allat_vbank_yn)",
      "name": "allat_vbank_yn",
      "value": "N"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "휴대폰 결제 사용여부<br>(allat_hp_yn)",
      "name": "allat_hp_yn",
      "value": "N"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품권 결제 사용여부<br>(allat_ticket_yn)",
      "name": "allat_ticket_yn",
      "value": "N"
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "과세여부<br>(allat_tax_yn)",
      "name": "allat_tax_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "할부 사용여부<br>(allat_sell_yn)",
      "name": "allat_sell_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "일반/무이자할부 사용여부<br>(allat_zerofee_yn)",
      "name": "allat_zerofee_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "포인트 사용여부<br>(allat_bonus_yn)",
      "name": "allat_bonus_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "현금영수증 발급여부<br>(allat_cash_yn)",
      "name": "allat_cash_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "결제정보 수신 E-Mail<br>(allat_email_addr)",
      "name": "allat_email_addr",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품 실물 여부<br>(allat_real_yn)",
      "name": "allat_real_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "신용카드 에스크로 적용여부<br>(allat_cardes_yn)",
      "name": "allat_cardes_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "계좌이체 에스크로 적용여부<br>(allat_abankes_yn)",
      "name": "allat_abankes_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "가상계좌 에스크로 적용여부<br>(allat_vbankes_yn)",
      "name": "allat_vbankes_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "휴대폰 에스크로 적용여부<br>(allat_hpes_yn)",
      "name": "allat_hpes_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "상품권 에스크로 적용여부<br>(allat_ticketes_yn)",
      "name": "allat_ticketes_yn",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "성별<br>(allat_gender)",
      "name": "allat_gender",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "생년월일<br>(allat_birth_ymd)",
      "name": "allat_birth_ymd",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "제공기간<br>(allat_provide_date)",
      "name": "allat_provide_date",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "선택결제수단<br>(allat_select_pay_type)",
      "name": "allat_select_pay_type",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "간편결제수단<br>(allat_spay_type)",
      "name": "allat_spay_type",
      "value": ""
    }
  }), _c('input', {
    attrs: {
      "type": "hidden",
      "placeholder": "부가세<br>(allat_vat_amt)",
      "name": "allat_vat_amt",
      "value": ""
    }
  })])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }