<template>
<div>
    <template v-if="!isMobile">
        <!-- pc form -->
        <form name="fm" ref="fm" method="POST" accept-charset="euc-kr" action="allat_approval.php"> <!--승인요청 및 결과수신페이지 지정 //-->
            <input v-model="shopId" type="hidden" placeholder="상점 ID" name="allat_shop_id" value="" size="19" maxlength=20>
            <input v-model="orderNo" type="hidden" placeholder="주문번호" name="allat_order_no" value="" size="19" maxlength=70>
            <input v-model="paymentAmount" type="hidden" placeholder="승인금액" name="allat_amt" value="" size="19" maxlength=10>
            <input type="hidden" placeholder="회원ID" name="allat_pmember_id" value="_" size="19" maxlength=20>
            <input type="hidden" placeholder="상품코드" name="allat_product_cd" value="_" size="19" maxlength=1000>
            <input v-model="orderName" type="hidden" placeholder="상품명" name="allat_product_nm" value="" size="19" maxlength=1000>
            <input v-model="buyerName" type="hidden" placeholder="결제자성명" name="allat_buyer_nm" value="" size="19" maxlength=20>
            <input v-model="buyerName" type="hidden" placeholder="수취인성명" name="allat_recp_nm" value="" size="19" maxlength=20>
            <input type="hidden" placeholder="수취인주소" name="allat_recp_addr" value="" size="19" maxlength=120>
            <input v-model="responseUrl" type="hidden" placeholder="인증정보수신URL" name="shop_receive_url" value="" size="19">
            <input v-model="test" type="hidden" name="allat_test_yn" size="19" maxlength=1>
            <input v-model.lazy="encData" type="hidden" placeholder="주문정보암호화필드" name=allat_enc_data value=''>
            <input v-model="buyerEmail" type="hidden" placeholder="결제 정보 수신 E-mail" name="allat_email_addr" value="" size="19" maxlength=50>

            <input type="hidden" placeholder="신용카드 결제 사용 여부" name="allat_card_yn" value="Y" size="19" maxlength=1>
            <input type="hidden" placeholder="계좌이체 결제<br>사용 여부" name="allat_bank_yn" value="N" size="19" maxlength=1>
            <input type="hidden" placeholder="무통장(가상계좌) 결제<br>사용 여부" name="allat_vbank_yn" value="N" size="19" maxlength=1>
            <input type="hidden" placeholder="휴대폰 결제<br>사용 여부" name="allat_hp_yn" value="N" size="19" maxlength=1>
            <input type="hidden" placeholder="상품권 결제<br>사용 여부" name="allat_ticket_yn" value="N" size="19" maxlength=1>
            <input type="hidden" placeholder="무통장(가상계좌)<br>인증 Key" name="allat_account_key" value="" size="19" maxlength=20>
            <input type="hidden" placeholder="과세여부" name="allat_tax_yn" value="Y" size="19" maxlength=1>
            <input type="hidden" placeholder="할부 사용여부" name="allat_sell_yn" value="Y" size="19" maxlength=1>
            <input type="hidden" placeholder="일반/무이자 할부<br>사용여부" name="allat_zerofee_yn" value="Y" size="19" maxlength=1>
            <input type="hidden" placeholder="포인트 사용 여부" name="allat_bonus_yn" value="N" size="19" maxlength=1>
            <input type="hidden" placeholder="현금 영수증 발급 여부" name="allat_cash_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="상품이미지 URL" name="allat_product_img" value="http://" size="19" maxlength=256>
            <input type="hidden" placeholder="상품 실물 여부: 상품이 실물일 경우 (Y), 상품이 실물이 아닐경우 (N) - Default : N" name="allat_real_yn" value="N" size="19" maxlength=1>
            <input type="hidden" placeholder="카드 에스크로<br>적용여부" name="allat_cardes_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="계좌이체 에스크로<br>적용여부" name="allat_bankes_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="무통장(가상계좌) 에스<br>크로 적용여부" name="allat_vbankes_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="휴대폰 에스크로<br>적용여부" name="allat_hpes_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="상품권 에스크로<br>적용여부" name="allat_ticketes_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="주민번호" name="allat_registry_no" value="" size="19" maxlength=13>
            <input type="hidden" placeholder="KB복합결제 적용여부" name="allat_kbcon_point_yn" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="제공기간" name="allat_provide_date" value="" size="19" maxlength=25>
            <input type="hidden" placeholder="성별" name="allat_gender" value="" size="19" maxlength=1>
            <input type="hidden" placeholder="생년월일" name="allat_birth_ymd" value="" size="19" maxlength=8>
        </form>
    </template>
    <template v-else>
        <!-- mobile form -->
        <form name="sendFm" method="post" accept-charset="euc-kr">
            <input v-model="shopId" type="hidden" placeholder="상점아이디<br>(allat_shop_id)" name="allat_shop_id" value="">
            <input v-model="orderNo" type="hidden" placeholder="주문번호<br>(allat_order_no)" name="allat_order_no" value="">
            <input v-model="paymentAmount" type="hidden" placeholder="승인금액<br>(allat_amt)"  name="allat_amt" value="">
            <input type="hidden" placeholder="회원ID<br>(allat_pmember_id)"  name="allat_pmember_id" value="_">
            <input type="hidden" placeholder="상품코드<br>(allat_product_cd)"  name="allat_product_cd" value="_">
            <input v-model="orderName" type="hidden" placeholder="상품명<br>(allat_product_nm)"  name="allat_product_nm" value="">
            <input v-model="buyerName" type="hidden" placeholder="결제자성명<br>(allat_buyer_nm)"  name="allat_buyer_nm" value="">
            <input v-model="buyerName" type="hidden" placeholder="수취인성명<br>(allat_recp_nm)"  name="allat_recp_nm" value="">
            <input type="hidden" placeholder="수취인주소<br>(allat_recp_addr)"  name="allat_recp_addr" value="">
            <input v-model="responseUrl" type="hidden" placeholder="인증정보수신URL<br>(shop_receive_url)"  name="shop_receive_url">
            <input v-model.lazy="encData" type="hidden" placeholder="주문정보암호화필드<br>(allat_enc_data)"  name="allat_enc_data">
            <input v-model="test" type="hidden" placeholder="테스트여부<br>(allat_test_yn)" name="allat_test_yn">
            <input type="hidden" placeholder="결제창크기자동조절 사용여부<br>(allat_autoscreen_yn)" name="allat_autoscreen_yn" value="Y">
            <input type="hidden" placeholder="신용카드 결제 사용여부<br>(allat_card_yn)" name="allat_card_yn" value="Y">
            <input type="hidden" placeholder="계좌이체 결제 사용여부<br>(allat_abank_yn)" name="allat_abank_yn" value="N">
            <input type="hidden" placeholder="가상계좌 결제 사용여부<br>(allat_vbank_yn)" name="allat_vbank_yn" value="N">
            <input type="hidden" placeholder="휴대폰 결제 사용여부<br>(allat_hp_yn)" name="allat_hp_yn" value="N">
            <input type="hidden" placeholder="상품권 결제 사용여부<br>(allat_ticket_yn)" name="allat_ticket_yn" value="N">
            <input type="hidden" placeholder="과세여부<br>(allat_tax_yn)" name="allat_tax_yn" value="">
            <input type="hidden" placeholder="할부 사용여부<br>(allat_sell_yn)" name="allat_sell_yn" value="">
            <input type="hidden" placeholder="일반/무이자할부 사용여부<br>(allat_zerofee_yn)" name="allat_zerofee_yn" value="">
            <input type="hidden" placeholder="포인트 사용여부<br>(allat_bonus_yn)" name="allat_bonus_yn" value="">
            <input type="hidden" placeholder="현금영수증 발급여부<br>(allat_cash_yn)" name="allat_cash_yn" value="">
            <input type="hidden" placeholder="결제정보 수신 E-Mail<br>(allat_email_addr)" name="allat_email_addr" value="">
            <input type="hidden" placeholder="상품 실물 여부<br>(allat_real_yn)" name="allat_real_yn" value="">
            <input type="hidden" placeholder="신용카드 에스크로 적용여부<br>(allat_cardes_yn)" name="allat_cardes_yn" value="">
            <input type="hidden" placeholder="계좌이체 에스크로 적용여부<br>(allat_abankes_yn)" name="allat_abankes_yn" value="">
            <input type="hidden" placeholder="가상계좌 에스크로 적용여부<br>(allat_vbankes_yn)" name="allat_vbankes_yn" value="">
            <input type="hidden" placeholder="휴대폰 에스크로 적용여부<br>(allat_hpes_yn)" name="allat_hpes_yn" value="">
            <input type="hidden" placeholder="상품권 에스크로 적용여부<br>(allat_ticketes_yn)" name="allat_ticketes_yn" value="">
            <input type="hidden" placeholder="성별<br>(allat_gender)" name="allat_gender" value="">
            <input type="hidden" placeholder="생년월일<br>(allat_birth_ymd)" name="allat_birth_ymd" value="">
            <input type="hidden" placeholder="제공기간<br>(allat_provide_date)" name="allat_provide_date" value="">
            <input type="hidden" placeholder="선택결제수단<br>(allat_select_pay_type)" name="allat_select_pay_type" value="">
            <input type="hidden" placeholder="간편결제수단<br>(allat_spay_type)" name="allat_spay_type" value="">
            <input type="hidden" placeholder="부가세<br>(allat_vat_amt)" name="allat_vat_amt" value="">
        </form>
    </template>
</div>
</template>

<script>
import api from "@/api";
export default {
    data(){
        let { orderNo, orderName, paymentMethod, paymentAmount, buyerName, buyerPhone, buyerEmail } = this.$route.query;

        return {
            orderNo,
            orderName,
            paymentMethod,
            paymentAmount,
            buyerName,
            buyerPhone,
            buyerEmail,
            encData: null,

            shopId: "",
            test: "",
        };
    },
    async mounted(){
        window.allatpayComplete = (payload) => {
            parent?.window?.allatpayComplete?.(payload);
        };
        window.allatpayClose = () => {
            parent?.window?.allatpayClose();
        };

        let { shopId, test } = await api.plugins.kg.allatpay.config();

        this.shopId = shopId;
        this.test = test;
        
        this.$nextTick(() => {
            if (navigator.userAgent.indexOf("Android") > -1 || navigator.userAgent.indexOf("iPhone") > -1) {
                Allat_Mobile_Approval(sendFm,0,0);
            } else {
                AllatPay_Approval(this.$refs.fm);
            }
        });
    },

    computed: {
        responseUrl(){
            return `${location.protocol}//${location.host}/api/plugins/kg/allatpay/response/${this.orderNo}`;
        },
        isMobile(){
            return navigator.userAgent.match("(.)*(iPhone|iPad|Android)(.)*") ? true : false;
        }
    }
}
</script>